<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('newsletter.title')"
        :sub-title="$t('newsletter.subtitle')"
    />

    <div>
      <filter-header
          :content="content"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderItems"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
                v-if="getAllNewsletter"
                :per-page="filterData.per_page"
                :items="getAllNewsletter"
                :fields="fields"
                :custimized-items="[
                { name: 'created_at' },
              ]"
            >
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    {{
                      moment(data.item.created_at).format(
                          ' DD MMMM YYYY, h:mm A'
                      )
                    }}</span
                  >
                </div>
              </template>


            </w-tables>
            <wameed-no-data
                v-if="getAllNewsletter && getAllNewsletter.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalNewsletter"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalNewsletter.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
    WameedBtn,
  },
  data() {
    return {
      filterData: {
        year: '',
        month: '',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
      },
      content: [
        {
          title: 'year',
          key: 'year',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
        {
          title: 'month',
          key: 'month',
          id: 'id',
          name: 'name',
          translate: true,
          selectedData: [],
          data: [],
        },
      ],
      fields: [

        {
          key: 'email',
          label: this.$i18n.t('newsletter.email'),
          sortable: true,
        },

        {
          key: 'created_at',
          label: this.$i18n.t('newsletter.created_at'),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllNewsletter: 'getAllNewsletter',
      getTotalNewsletter: 'getTotalNewsletter',
      getFilterData: 'getFilterData',
    }),
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[0].data = newVal.years ? newVal.years : [];
        this.content[1].data = newVal.months ? newVal.months : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadFilterData('newsletters');
  },
  methods: {
    ...mapActions({
      loadNewsletter: 'loadNewsletter',
      loadFilterData: 'loadFilterData',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadNewsletter(this.filterData);
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderItems(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

  }

};
</script>
